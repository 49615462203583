.versionControl .invalid-feedback {
  width: 100% !important;
  padding-bottom: 4px !important;
  padding-top: 4px !important;
}
.cqrnext {
  background: #e6e7e8;
}
.cqrnext:hover {
  color: #3d5ba9;
}
.cqrback {
  background: #e6e7e8;
}
.cqrback:hover {
  color: #3d5ba9;
}
