.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
}
.dropzone-accept {
  border-color: #a0c46d !important;
}

.dropzone-reject {
  border-color: #b71d1a !important;
}
