.control-panel {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 100px;
  margin: 20px;
  font-size: 11px;
  line-height: 1.2;
  text-transform: uppercase;
  outline: none;
}

.white-text {
  color: #f0f0f0;
  text-shadow: 1px black;
}

.black-text {
  color: rgb(5, 5, 5);
  text-shadow: 1px white;
}
