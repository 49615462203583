/* NotificationBar */

.notify-assets {
  min-height: 70vh;
}

.notification-widget .close-button {
  background-color: transparent;
  border: none;
  color: #b71d1a !important;
}

.close-button .text-danger:hover {
  background-color: transparent;
  border: none;
  color: #eb4f35 !important;
}

.notification-widget .close {
  padding: 0rem 1rem !important;
  margin: 0 !important;
}

.notification-widget .MuiListItem-button:hover {
  background-color: white;
}

.notification-widget .alert-icon {
  font-size: 0.7rem !important;
  position: relative;
  margin-bottom: 1rem;
  padding: 2px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.notification-widget .asset-count {
  width: 27px;
  background-color: #A0C46D !important;
}

.notification-widget a {
  color: inherit;
  text-decoration: none;
}

.notification-widget .pointer:hover {
  color: inherit;
}

.alertOne {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  box-shadow: 0px 0px 3px 3px #aaaaaa;
  background-color: #e6e7e8;
}

.notification-widget .widget-header {
  margin-top: -12px;
  padding-top: 1rem;
}

.notification-alerts {
  height: 90%;
}

.MuiExpansionPanel-rounded {
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}

.widget-cog:hover {
  color: #3d5ba9 !important;
}

.notification-widget {
  overflow: auto;
}

.notification-widget.min-width {
  min-width: 33rem;
}

.notification-widget .widget-cog {
  padding-top: 1.5rem;
}

.close:hover {
  color: white;
}

.close {
  font-size: 2rem;
}

.modal-title {
  font-size: 1.2rem !important;
  padding-top: 0.1rem;
}

.modal-header {
  padding-top: 0.3rem;
  padding-bottom: 0.4rem;
}

.modal-body {
  padding-top: 5px;
  padding-bottom: 5px;
}
